import {formFieldNames, isRequired} from '@mb-react/svarog-ui';
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {useGlobalValidation} from "../../../../../hooks/useGlobalValidation";

export const legalEntityFormValidation = (values) => {
    const globalValidation = useGlobalValidation()

    const validate = (values) => {

        let errors = {}

        if (globalValidation.isMandatory(values, formFieldNames.LE_TPID)) {
            isRequired(values, formFieldNames.LE_TPID, errors);
        }

        if(values[formFieldNames.LE_TPID] && values[formFieldNames.LE_TPID].length < 14){
            errors[formFieldNames.LE_TPID] = "uenLength";
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_NAME)) {
            isRequired(values, formFieldNames.LE_NAME, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_TYPE)) {
            isRequired(values, formFieldNames.LE_TYPE, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_SUBTYPE)) {
            isRequired(values, formFieldNames.LE_SUBTYPE, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_COUNTRY)) {
            isRequired(values, formFieldNames.LE_ADDRESS_COUNTRY, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_COUNTRY_CODE)) {
            isRequired(values, formFieldNames.LE_COUNTRY_CODE, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_PROVINCE)) {
            isRequired(values, formFieldNames.LE_ADDRESS_PROVINCE, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_CITY)) {
            isRequired(values, formFieldNames.LE_ADDRESS_CITY, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_REGION)) {
            isRequired(values, formFieldNames.LE_ADDRESS_REGION, errors);
        }

        if (globalValidation.isMandatory(values, genericFormFieldNames.LE_ADDRESS_VILLAGE)) {
            isRequired(values, genericFormFieldNames.LE_ADDRESS_VILLAGE, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_STREET)) {
            isRequired(values, formFieldNames.LE_ADDRESS_STREET, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_HOUSE_NUMBER)) {
            isRequired(values, formFieldNames.LE_ADDRESS_HOUSE_NUMBER, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_ADDRESS_ZIP_CODE)) {
            isRequired(values, formFieldNames.LE_ADDRESS_ZIP_CODE, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_QUARTER_NAME)) {
            isRequired(values, formFieldNames.LE_QUARTER_NAME, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_SECTOR_NUMBER)) {
            isRequired(values, formFieldNames.LE_SECTOR_NUMBER, errors);
        }

        if (globalValidation.isMandatory(values, formFieldNames.LE_STATE)) {
            isRequired(values, formFieldNames.LE_STATE, errors);
        }

        return errors;
    }
    return {validate}
}