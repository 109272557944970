export const DICTIONARY = 'DICTIONARY';

export const projectDictionaries = {
  PROFESSION: 'professions',
  INFO: 'info',
  GOVERNORATE: 'governorate',
  VILLAGES: 'villages',
  COUNTRY_CODES: 'icao_countries',
  NATIONALITIES: 'icao_nationalities',
  BLOOD_GROUP: 'blood_group',
  PROVINCES: 'provinces',
  CITIES: 'cities',
  FOOD: 'food',
  GENDER_CODES: 'gender',
  MARITAL_CODES: 'marital_status',
  BUILDING_CLASS: 'building_class',
  CARD_RESTRICTIONS: 'card_restrictions',
  HOLDER_RESTRICTIONS: 'holder_restrictions',
  FRONT_TINT_PERCENTAGE: 'front_tint_percentage',
  REAR_TINT_PERCENTAGE: 'rear_tint_percentage',

  VEHICLE_BRANDS: 'vehicle_brands',
  TRAILER_BRANDS: 'trailer_brands',
  VEHICLE_MODELS: 'vehicle_models',
  TRAILER_MODELS: 'trailer_models',
  TRAILER_SUBCATEGORIES: 'trailer_subcategories',
  VEHICLE_CATEGORIES: 'vehicle_categories',
  TRAILER_CATEGORIES: 'trailer_categories',
  VEHICLE_SUBCATEGORIES: 'vehicle_subcategories',
  VEHICLE_USAGE_TYPES: 'vehicle_usage_types',
  VEHICLE_COLORS: 'vehicle_colors',
  VEHICLE_WHEEL_TYPES: 'vehicle_wheel_types',
  VEHICLE_SUB_CATEGORY: 'vehicle_subcategory',
  VEHICLE_FUEL_TYPES: 'vehicle_fuel_types',
  VEHICLE_CABIN_TYPES: "vehicle_cabin_types",
  VEHICLE_CABIN_SIZES: "vehicle_cabin_sizes",

  PLATE_TYPES: "plate_types",
  PLATE_CATEGORIES: "plate_categories",
  PLATE_USAGES: "plate_usages",
  STATE_HANDLING_REASONS: "state_handling_reasons",
  PLATE_USAGE_STANDARD: "plate_usage_standard",
  PLATE_USAGE_SPECIAL: "plate_usage_special",
  PLATE_USAGE_MOTORCYCLE: "plate_usage_motorcycle",
  PLATE_USAGE_OTHER: "plate_usage_other",
  PLATE_USAGE: "plate_usage",
  PLATE_CATEGORY: "plate_category",
};

export const dictionaryReference = {
  VILLAGES_PLACE_OF_BIRTH: 'villages_of_birth',
  CITIES_PLACE_OF_BIRTH: 'cities_of_birth',
  VILLAGES_ADDRESS: 'villages_address',
  CITIES_ADDRESS: 'cities_address',
  CATERING_CENTER_NUMBER: 'catering_center_number',
  VILLAGES_OF_REGISTRATION: 'villages_of_registration',
  CITIES_OF_REGISTRATION: 'cities_of_registration',
  VEHICLE_MODELS: 'vehicle_models',
  TRAILER_MODELS: 'trailer_models',
  VEHICLE_SUBCATEGORIES: 'vehicle_subcategories',
  TRAILER_SUBCATEGORIES: 'trailer_subcategories',
  LEGAL_ENTITY_TYPE: 'legal_entity_type',
  LEGAL_ENTITY_SUBTYPE: 'legal_entity_subtype',
  VEHICLE_FUEL_TYPES: 'vehicle_fuel_types'
};