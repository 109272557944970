import {EUseCases} from "@mb-react/svarog-ui";
import {formFieldsQuestionnaire, formFieldsVRQuestionnaire} from "../constants/form";
import {NO, YES} from "../constants/general";
import {changeOwnershipSubUseCasesList, genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {I18n} from "react-redux-i18n";
import {linkedPersonTypes, formFieldNames, identificationNumberTypes} from "@mb-react/svarog-ui";
import {prepareServerStringDate} from "../sagas/generators/sagaUtil";

export function prepareFetchedDLCategoryForDropdown(DLCategoryList) {
    if (DLCategoryList.length > 0) {
        let processedDLCategoryList = [];
        for (let i = 0; i < DLCategoryList.length; i++) {
            if (DLCategoryList[i].className && DLCategoryList[i].className.includes(".")) {
                processedDLCategoryList.push(DLCategoryList[i].className.split(".").slice(-1).toString())
            } else {
                processedDLCategoryList.push(DLCategoryList[i].className)
            }
        }
        return processedDLCategoryList;
    }

    return DLCategoryList;
}

export function setQuestionnaireBasedOnUseCase(useCase) {
    let questionnaireInfo;

    if (useCase === EUseCases.NEW) {
        questionnaireInfo = {
            [formFieldsQuestionnaire.IS_NEW_DOCUMENT]: YES,
            [formFieldsQuestionnaire.ADDITIONAL_QUESTION]: formFieldsQuestionnaire.FIRST_TIME_APPLY
        }
    }
    if (useCase === EUseCases.RE_REGISTER) {
        questionnaireInfo = {
            [formFieldsQuestionnaire.IS_NEW_DOCUMENT]: YES,
            [formFieldsQuestionnaire.ADDITIONAL_QUESTION]: formFieldsQuestionnaire.OLD_DOCUMENT
        }
    }

    if (useCase === EUseCases.FOREIGN) {
        questionnaireInfo = {
            [formFieldsQuestionnaire.IS_NEW_DOCUMENT]: YES,
            [formFieldsQuestionnaire.ADDITIONAL_QUESTION]: formFieldsQuestionnaire.FOREIGN_DOCUMENT
        }
    }

    if (useCase === EUseCases.CANCEL_DOCUMENT) {
        questionnaireInfo = {
            [formFieldsQuestionnaire.IS_NEW_DOCUMENT]: NO,
            [formFieldsQuestionnaire.IS_REPLACE_DOCUMENT]: NO,
            [formFieldsQuestionnaire.IS_MODIFY_DOCUMENT]: NO,
            [formFieldsQuestionnaire.IS_EXTEND_DOCUMENT]: NO,
            [formFieldsQuestionnaire.IS_CANCEL_DOCUMENT]: YES
        }
    }

    return questionnaireInfo;
}

export function setQuestionnaireBasedOnVRUseCase(useCase, subUseCase) {
    let questionnaireInfo;

    if (useCase === EUseCases.NEW) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_NEW_VR]: YES,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.FIRST_TIME_APPLY_VR
        }
    }
    if (useCase === EUseCases.REPLACE) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_REPLACE_VR]: YES,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.LOST_VR,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.DAMAGED_VR,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.EXPIRED_VR,
        }
    }
    if (useCase === EUseCases.MODIFY_VEHICLE_DATA) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_MODIFY_VR]: YES,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.EXPIRED_VR3,
        }
    }
    if (useCase === EUseCases.EXCHANGE_REGISTRATION) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_EXTEND_VR]: YES,
        }
    }
    if (useCase === EUseCases.RESERVE_PLATE_NUMBER) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR]: YES,
        }
    }
    if (useCase === EUseCases.CHANGE_OWNERSHIP) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_NEW_VR]: NO,
            [formFieldsVRQuestionnaire.IS_REPLACE_VR]: NO,
            [formFieldsVRQuestionnaire.IS_MODIFY_VR]: NO,
            [formFieldsVRQuestionnaire.IS_EXCHANGE_VR]: NO,
            [formFieldsVRQuestionnaire.IS_EXTEND_VR]: NO,
            [formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR]: NO,
            [formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR]: YES,
        }
    }

    if (subUseCase) {
        if (subUseCase === changeOwnershipSubUseCasesList.SALE_AND_BUY) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.SALE_AND_BUY
            }
        }
        if (subUseCase === changeOwnershipSubUseCasesList.ADD_OWNER) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.SALE_AND_BUY
            }
        }
        if (subUseCase === changeOwnershipSubUseCasesList.DELETE_OWNER) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.DELETE_OWNER
            }
        }
        if (subUseCase === changeOwnershipSubUseCasesList.CHANGE_SHARE) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.CHANGE_SHARE
            }
        }
        if (subUseCase === changeOwnershipSubUseCasesList.CHANGE_LEAD) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.CHANGE_LEAD
            }
        }
        if (subUseCase === changeOwnershipSubUseCasesList.BY_COURT) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.BY_COURT
            }
        }
        if (subUseCase === changeOwnershipSubUseCasesList.BY_AUCTION) {
            questionnaireInfo = {
                ...questionnaireInfo,
                [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: changeOwnershipSubUseCasesList.BY_AUCTION
            }
        }
    }

    if (useCase === EUseCases.RE_REGISTER) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_NEW_VR]: YES,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.OLD_VR
        }
    }


    if (useCase === EUseCases.FOREIGN) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_NEW_VR]: YES,
            [formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR]: formFieldsVRQuestionnaire.FOREIGN_VR
        }
    }

    if (useCase === EUseCases.CANCEL) {
        questionnaireInfo = {
            [formFieldsVRQuestionnaire.IS_NEW_VR]: NO,
            [formFieldsVRQuestionnaire.IS_REPLACE_VR]: NO,
            [formFieldsVRQuestionnaire.IS_MODIFY_VR]: NO,
            [formFieldsVRQuestionnaire.IS_EXCHANGE_VR]: NO,
            [formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR]: NO,
            [formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR]: NO,
            [formFieldsVRQuestionnaire.IS_EXTEND_VR]: NO,
            [formFieldsVRQuestionnaire.IS_CANCEL_VR]: YES
        }
    }

    return questionnaireInfo;
}

export function prepareFetchedBloodGroupTypeForDDL(bloodGroup) {
    let mappedBloodGroup;

    switch (bloodGroup.toUpperCase()) {
        case "NULL_POSITIVE":
            mappedBloodGroup = "Null_Positive";
            break;
        case "NULL_NEGATIVE":
            mappedBloodGroup = "Null_Negative";
            break;
        case "A_POSITIVE":
            mappedBloodGroup = "A_Positive";
            break;
        case "A_NEGATIVE":
            mappedBloodGroup = "A_Negative";
            break;
        case "B_POSITIVE":
            mappedBloodGroup = "B_Positive";
            break;
        case "B_NEGATIVE":
            mappedBloodGroup = "B_Negative";
            break;
        case "AB_POSITIVE":
            mappedBloodGroup = "AB_Positive";
            break;
        case "AB_NEGATIVE":
            mappedBloodGroup = "AB_Negative";
            break;
        case "UNKNOWN":
            mappedBloodGroup = "Unknown";
            break;
        default:
            mappedBloodGroup = bloodGroup
    }

    return mappedBloodGroup;
}

export const prepareSpecificDictionaryElementForDropdown = (store, key) => {
    let singleDictionary = []
    if (store?.mbDictionaryReducer?.dictionaries && key) {
        singleDictionary = store.mbDictionaryReducer.dictionaries.filter(x => x.key == key)
    }
    if (singleDictionary && singleDictionary.length > 0) {

        //extract first index due tu multi layers
        singleDictionary = singleDictionary[0].items;
        let returnValue = singleDictionary.map(x => (
            {
                label: I18n.t('label.' + x.values.kr),
                value: x.key
            }
        ))

        return returnValue
    }
    return []
}

export const b64ToStringEncoder = (value) => {
    if(!value){
        return null;
    }
    
    let byteCharacters = atob(value);
    let byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
    }

    // Decode Uint8Array with UTF-8
    let textDecoder = new TextDecoder('utf-8');
    return textDecoder.decode(byteArray);
}

export function removeLeadingZero(str) {
    return str.replace(/^0+/, '');
}

export function prepareProxyForLinkedPerson(applicantDTO, proxy) {

    if(!proxy){
        return applicantDTO;
    }

    let listOfLinkedPerson = applicantDTO?.demographicDataDTO?.linkedPersons ? applicantDTO?.demographicDataDTO?.linkedPersons : [];

    listOfLinkedPerson = removeProxyIfExists(listOfLinkedPerson);

    let proxyLinkedPerson = mapProxyToPersonDataDTO(proxy);

    listOfLinkedPerson.push(proxyLinkedPerson)

    applicantDTO.demographicDataDTO.linkedPersons = listOfLinkedPerson;

    let listOfGenericFields = applicantDTO?.genericFieldsDTO?.genericFieldDTOList;

    listOfGenericFields = removeProxyRelatedGenericFields(listOfGenericFields)

    listOfGenericFields = mapProxyToApplicantGenericFields(listOfGenericFields, proxy);

    applicantDTO.genericFieldsDTO.genericFieldDTOList = listOfGenericFields

    return applicantDTO;
}

export function mapProxyToApplicantGenericFields(listOfGenericFields, proxy){

    if(!listOfGenericFields){
        listOfGenericFields = []
    }

    if(proxy[genericFormFieldNames.PROXY_FATHER_NAME]){
        let gfList = {
            key: "proxyFatherName",
            value: proxy[genericFormFieldNames.PROXY_FATHER_NAME],
            genericFieldDataType: "STRING"
        }
        listOfGenericFields.push(gfList)
    }

    if(proxy[genericFormFieldNames.PROXY_GRANDFATHER_NAME]){
        let gfList = {
            key: "proxyGrandfatherName",
            value: proxy[genericFormFieldNames.PROXY_GRANDFATHER_NAME],
            genericFieldDataType: "STRING"
        }
        listOfGenericFields.push(gfList)
    }

    if(proxy[genericFormFieldNames.PROXY_LETTER_NUMBER]){
        let gfList = {
            key: "proxyLetterNumber",
            value: proxy[genericFormFieldNames.PROXY_LETTER_NUMBER],
            genericFieldDataType: "STRING"
        }
        listOfGenericFields.push(gfList)
    }

    if(proxy[genericFormFieldNames.PROXY_LETTER_DATE]){
        let gfList = {
            key: "proxyLetterDate",
            value: prepareServerStringDate(proxy[genericFormFieldNames.PROXY_LETTER_DATE]),
            genericFieldDataType: "STRING"
        }
        listOfGenericFields.push(gfList)
    }

    if(proxy[genericFormFieldNames.PROXY_LETTER_AUTHORITY]){
        let gfList = {
            key: "proxyLetterAuthority",
            value: proxy[genericFormFieldNames.PROXY_LETTER_AUTHORITY],
            genericFieldDataType: "STRING"
        }
        listOfGenericFields.push(gfList)
    }

    return listOfGenericFields;

}

export function mapProxyToPersonDataDTO(proxy) {

    let personDataDTO = {
        linkedType: linkedPersonTypes.PROXY,
        givenName: proxy[formFieldNames.PROXY_NAME],
        surname: proxy[formFieldNames.PROXY_SURNAME],
        gender: proxy[formFieldNames.PROXY_GENDER],
        dateOfBirth: prepareServerStringDate(proxy[formFieldNames.PROXY_DATE_OF_BIRTH])
    }

    if (proxy[formFieldNames.PROXY_PERSONAL_NUMBER]) {
        personDataDTO.identificationNumbers = [{
          personalNumber: proxy[formFieldNames.PROXY_PERSONAL_NUMBER],
          personalNumberType: identificationNumberTypes.NATIONAL_ID
        }]
    }

    return personDataDTO;

}

export function removeProxyIfExists(listOfLinkedPerson){
    if(listOfLinkedPerson) {
        listOfLinkedPerson = listOfLinkedPerson.filter(item => item.linkedType !== linkedPersonTypes.PROXY);
    }

    return listOfLinkedPerson
}

export function removeProxyRelatedGenericFields (listOfGenericFields){

    let listOfProxyGenericFields = ["proxyFatherName", "proxyGrandfatherName", "proxyLetterNumber", "proxyLetterDate", "proxyLetterAuthority"]

    if(listOfGenericFields){
        listOfGenericFields = listOfGenericFields.filter(item => !listOfProxyGenericFields.includes(item.key))
    }

    return listOfGenericFields
}