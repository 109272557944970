import { formFieldNames, isRequired } from "@mb-react/svarog-ui";
import { useGlobalValidation } from "../../../../../hooks/useGlobalValidation";
import { useSelector } from "react-redux";
import { getFormValues } from "@mb-react/mb-react-core";
import { forms } from "../../../../../constants/form";
import {isTrailerType, isVehicleType} from "../../../../../util/businesslogicUtil";

export const vehicleDataValidation = (values) => {
  const globalValidation = useGlobalValidation()
  const vehicleDataFormValues = useSelector((state) => getFormValues(state, forms.VEHICLE_IDENTIFICATION))


  const validate = (values) => {
    const errors = {};

    values = {...values, ...vehicleDataFormValues}
    //Temporary solution for validating fields until digitsOnly prop is fixed

    const fieldsToValidate = [
      formFieldNames.ENGINE_POWER,
      formFieldNames.ENGINE_CAPACITY,
      formFieldNames.LOAD_WEIGHT,
      formFieldNames.MAXIMUM_SPEED,
      formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES,
      formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES,
      formFieldNames.VERTICAL_LOAD,
      formFieldNames.TOTAL_WEIGHT,
      formFieldNames.VEHICLE_LENGTH,
      formFieldNames.VEHICLE_WIDTH,
      formFieldNames.VEHICLE_HEIGHT,
      formFieldNames.SEATS,
      formFieldNames.AXIS
    ]

    let regexNumbersOnly = /^[0-9]*$/;
    for (let fieldName of fieldsToValidate) {
      let fieldValidation = values[fieldName];
      if (fieldValidation != undefined) {
        if (fieldValidation && !fieldValidation.toString().match(regexNumbersOnly)) {
          errors[fieldName] = "onlyNumbersAllowed";
        }
      }
    }


    if (!values) {
      errors.validation = 'Form values undefined!'
    } else {
      if (globalValidation.isMandatory(values, formFieldNames.VERTICAL_LOAD)) {
        isRequired(values, formFieldNames.VERTICAL_LOAD, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.WHEEL_SIZE)) {
        isRequired(values, formFieldNames.WHEEL_SIZE, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.AXIS) && isTrailerType(vehicleDataFormValues)) {
        isRequired(values, formFieldNames.AXIS, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.TOTAL_WEIGHT)) {
        isRequired(values, formFieldNames.TOTAL_WEIGHT, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_LENGTH)) {
        isRequired(values, formFieldNames.VEHICLE_LENGTH, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_HEIGHT)) {
        isRequired(values, formFieldNames.VEHICLE_HEIGHT, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_WIDTH)) {
        isRequired(values, formFieldNames.VEHICLE_WIDTH, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.CYLINDERS)) {
        isRequired(values, formFieldNames.CYLINDERS, errors);
      }
      // if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_MODEL)) {
      //   isRequired(values, formFieldNames.VEHICLE_MODEL, errors);
      // }
      if (globalValidation.isMandatory(values, formFieldNames.MAXIMUM_SPEED)) {
        isRequired(values, formFieldNames.MAXIMUM_SPEED, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.LOAD_WEIGHT)) {
        isRequired(values, formFieldNames.LOAD_WEIGHT, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES)) {
        isRequired(values, formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES)) {
        isRequired(values, formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.FUEL) && isVehicleType(vehicleDataFormValues)) {
        isRequired(values, formFieldNames.FUEL, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.ENGINE_CAPACITY)) {
        isRequired(values, formFieldNames.ENGINE_CAPACITY, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.ENGINE_POWER)) {
        isRequired(values, formFieldNames.ENGINE_POWER, errors);
      }
    }
    return errors;
  }
  return {validate};
};