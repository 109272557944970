import {
    genericFormFieldNames,
    applicationGenericFieldsList,
    applicantGenericFieldsList,
    identityGenericFieldsList
} from "@mb-react/krg-getid-form-data-common";
import {
    addToAppStore,
    getFormValues,
    getDictionaryByKey,
    hasValidationErrors,
    getFormErrors,
    addMultipleErrorToStep,
    crateErrorObject, getFromAppStore, showToast, toastIDs
} from "@mb-react/mb-react-core";
import {Button, Dialog, Message} from "@mb-react/mb-react-core";
import {
    extractFormValuesToApplicationData, extractFormValuesToApplicationDataWithMultiData, extractFormValuesToPersonData,
    formFieldNames, getCookie,
} from "@mb-react/svarog-ui";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {REDIRECT_TO_APPOINTMENT, ROOT} from "../../../../../constants/environment";
import {NATURAL_PERSON} from "../../../../../constants/application";
import {getPath, i18nl, TRG} from "../../../../../constants/i18n";
import {loadFile, prepareServerStringDate} from "../../../../../sagas/generators/sagaUtil";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {useCreateServiceWrapper} from "../../../../../services/service";
import {dictionaryReference, projectDictionaries, SELECTED_EXISTING_ACCOUNT} from "../../../../../constants/general";
import {getLocalSelectOptionTranslation} from "../../../../componentUtil";
import {forms} from "../../../../../constants/form";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import {
    isNotRegisteredUserRole,
    isOperationModeNotProtected,
    isOperationModeProtected
} from "../../../../../util/businesslogicUtil";
import {onCancel} from "../../../../../util/componentUtil";
import {b64ToStringEncoder} from "../../../../../util/dataPreparationUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

let summaryReportPayload = null;
const SummaryTaskWrapper = () => {
    let formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let formValuesPersonalInformation = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION))
    let formValuesAddressData = useSelector((state) => getFormValues(state, forms.ADDRESS_DATA))
    let formValuesDL = useSelector((state) => getFormValues(state, forms.DRIVER_LICENSE))

    const isPersonalInformationFormValid = useSelector((state) => hasValidationErrors(state, forms.PERSONAL_INFORMATION));
    const isAddressDataValid = useSelector((state) => hasValidationErrors(state, forms.ADDRESS_DATA));
    const isDriverLicenseFormValid = useSelector((state) => hasValidationErrors(state, forms.DRIVER_LICENSE));

    const personalInformationFormErrors = useSelector((state) => getFormErrors(state, forms.PERSONAL_INFORMATION));
    const addressDataErrors = useSelector((state) => getFormErrors(state, forms.ADDRESS_DATA));
    const driverLicenseFormErrors = useSelector((state) => getFormErrors(state, forms.DRIVER_LICENSE));

    let formValues = {...formValuesApplication, ...formValuesPersonalInformation, ...formValuesAddressData, ...formValuesDL}

    const errors = {}
    const [showDraftDialog, setShowDraftDialog] = useState(false);
    const [showApplicationSubmittedModal, setShowApplicationSubmittedModal] = useState(false);
    const [driversLicenseData, setDriversLicenseData] = useState([]);

    const provinceOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_OF_REGISTRATION))
    const villageOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_OF_REGISTRATION))
    const countryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.COUNTRY_CODES))
    const provincesOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_PLACE_OF_BIRTH))
    const villageOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
    const provincesAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_ADDRESS))
    const infoCenterAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.INFO))
    const villageAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_ADDRESS))
    const buildingClassAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BUILDING_CLASS))
    const genderOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.GENDER_CODES))
    const maritalOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.MARITAL_CODES))
    const foodCenterOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD_CENTER_INDICATOR))
    const cateringCenterProvinceOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cateringCenterNumberOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD))
    const professionOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROFESSION))
    const nationalityOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.NATIONALITIES))
    const bloodGroupOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BLOOD_GROUP))
    const driversLicenseDataOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.DRIVERS_LICENSE_DATA))

    const dispatch = useDispatch();
    const handleCommonServices = useHandleCommonServices();
    const callService = useCreateServiceWrapper();
    const [draftApplication, setDraftApplication] = useState(false)

    const [applicationReceipt, setApplicationReceipt] = useState();
    const selectedPersonForDL = useSelector((state) => getFromAppStore(state, "selectedPersonForDL"));
    const enrollPersonFromScratch = useSelector((state) => getFromAppStore(state, "enrollPersonFromScratch"));
    const applyWoAccount = getCookie("useApplyWithoutAccount")
    const isExistingAccountSelected = getCookie(SELECTED_EXISTING_ACCOUNT)
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS));

    const missingMaritalData = useSelector((state) => getFromAppStore(state, applicationStore.MISSING_SPOUSE_MARITAL_DATA))

    useEffect(() => {
        let DLData = formValues && Array.isArray(formValues[formFieldNames.DRIVERS_LICENSE_DATA]) && formValues[formFieldNames.DRIVERS_LICENSE_DATA].map(x => {
            return {
                [formFieldNames.DRIVER_LICENSE_CATEGORY]: x
            };
        });

        setDriversLicenseData(DLData);
    }, []);

    useEffect(() => {
        if (applicationReceipt) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
                "<html><head><title>PRINT SUMMARY</title></head><body><iframe title='PRINT SUMMARY'  width='100%' height='100%' src='data:application/pdf;base64, " + applicationReceipt + "'></iframe></body></html>"
            );
        }
    }, [applicationReceipt]);

    function formatDriversLicenseData(formValue) {
        if (Array.isArray(formValue)) {
            return formValue.map((value, index) => value + (formValue.length - 1 > index ? ',' : ''));
        } else if (typeof formValue === 'object') {
            return Object.keys(formValue).join(',');
        } else {
            return getLocalSelectOptionTranslation(driversLicenseDataOptions, formValue);
        }
    }

    const dynamicColumns = Object.keys(formValues).map((x, i) => {

        if (["applicationType", "workflow", "ownerRole", "enrollmentStatus"].includes(x)) {
            return;
        }

        return formValues[x] && <div className="grid IDsummary" key={i}>
            <div className="col-12 xl:col-6">
                {I18n.t("label." + x)}
            </div>
            <div className="col-12 xl:col-6 summaryValue">
                {decorateField(x, formValues[x])}
            </div>
        </div>
    });

    function decorateField(formLabel, formValue) {
        switch (formLabel) {
            case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(provinceOfRegistrationOptions, formValue)
            case genericFormFieldNames.CITY_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(cityOfRegistrationOptions, formValue)
            case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(villageOfRegistrationOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_COUNTRY:
                return getLocalSelectOptionTranslation(countryOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
                return getLocalSelectOptionTranslation(provincesOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_CITY:
                return getLocalSelectOptionTranslation(cityOptions, formValue)
            case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:
                return getLocalSelectOptionTranslation(villageOptions, formValue)
            case formFieldNames.ADDRESS_PROVINCE:
                return getLocalSelectOptionTranslation(provincesAddressOptions, formValue)
            case formFieldNames.ADDRESS_CITY:
                return getLocalSelectOptionTranslation(cityAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_VILLAGE:
                return getLocalSelectOptionTranslation(villageAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_INFORMATION_CENTER:
                return getLocalSelectOptionTranslation(infoCenterAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_BUILDING_CLASS:
                return getLocalSelectOptionTranslation(buildingClassAddressOptions, formValue)
            case formFieldNames.GENDER:
                return getLocalSelectOptionTranslation(genderOptions, formValue)
            case formFieldNames.MARITAL_STATUS:
                return getLocalSelectOptionTranslation(maritalOptions, formValue)
            case genericFormFieldNames.FOOD_CENTER_INDICATOR:
                const selectedOption = getLocalSelectOptionTranslation(foodCenterOptions, formValue);
                return selectedOption === "NO" ? "NO" : "YES";
            case genericFormFieldNames.CATERING_CENTER_PROVINCE:
                return getLocalSelectOptionTranslation(cateringCenterProvinceOptions, formValue)
            case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
                return getLocalSelectOptionTranslation(cateringCenterNumberOptions, formValue)
            case formFieldNames.POSITION:
                return getLocalSelectOptionTranslation(professionOptions, formValue)
            case formFieldNames.NATIONALITY:
                return getLocalSelectOptionTranslation(nationalityOptions, formValue)
            case formFieldNames.BLOOD_GROUP:
                return getLocalSelectOptionTranslation(bloodGroupOptions, formValue)
            case formFieldNames.DRIVERS_LICENSE_DATA:
                return formatDriversLicenseData(formValue)
            case "oldLicenseClasses":
                return formatDriversLicenseData(formValue)
            case "enrolmentStarted":
                return prepareServerStringDate(formValue)
            case formFieldNames.USE_CASE:
                return I18n.t("label." + formValue)
            case formFieldNames.DOCUMENT_TYPE:
                return I18n.t("label." + formValue)
            case "operatorUsername":
                return b64ToStringEncoder(formValue)
            default:
                return typeof formValue == "string" || typeof formValue == "number" ? formValue : prepareServerStringDate(formValue)
        }
    }


    const isFormComplete = () => {
        if (isPersonalInformationFormValid || isAddressDataValid || isDriverLicenseFormValid) {
            return false
        } else {
            return true
        }
    };

    //Dear Lord...
    const prepareApplicantLatinNamesToGenericFields = applicant => {
        let genericFields = [];
        applicant?.demographicDataDTO?.linkedPersons.forEach(e => {
            if (e.givenNameLatin && e.linkedType && e.linkedType !== "SPOUSE") {
                genericFields.push({
                    key: e.linkedType.toLowerCase() + "NameLatin",
                    value: e.givenNameLatin,
                    genericFieldDataType: "STRING"
                })
            }
        })

        genericFields.push({
            key: "givenNameLatin",
            value: applicant?.demographicDataDTO?.personDataDTO?.givenNameLatin,
            genericFieldDataType: "STRING"
        })

        genericFields.push({
            key: "surnameLatin",
            value: applicant?.demographicDataDTO?.personDataDTO?.surnameLatin,
            genericFieldDataType: "STRING"
        })

        if(missingMaritalData){
            if(formValuesPersonalInformation?.["spouseFathersName"]){
                genericFields.push({
                    key: "spouseFathersName",
                    value: formValuesPersonalInformation?.["spouseFathersName"],
                    genericFieldDataType: "STRING"
                })
            }

            if(formValuesPersonalInformation?.["spouseGrandfathersName"]){
                genericFields.push({
                    key: "spouseGrandfathersName",
                    value: formValuesPersonalInformation?.["spouseFathersName"],
                    genericFieldDataType: "STRING"
                })
            }
        }

        let preparedApplicant = {
            ...applicant,
            genericFieldsDTO: {
                genericFieldDTOList: [
                    ...applicant.genericFieldsDTO.genericFieldDTOList,
                    ...genericFields
                ]
            }
        }

        return [preparedApplicant];
    }

    const saveApplication = (state) => {

        if (isOperationModeProtected()) {
            let requestObject = {};
            if (selectedPersonForDL && selectedPersonForDL.applicantData) {
                let selectedApplicant = prepareApplicantLatinNamesToGenericFields(selectedPersonForDL.applicantData);
                requestObject = extractFormValuesToApplicationDataWithMultiData({
                    ...formValues,
                    [formFieldNames.ORIGIN_ID]: isOperationModeProtected() ? "SC" : "WP",
                    [formFieldNames.APPLICATION_STATUS]: state,
                    [formFieldNames.DRIVERS_LICENSE_DATA]: driversLicenseData,
                    [formFieldNames.APPLICANT_TYPE]: NATURAL_PERSON,
                    [formFieldNames.OPERATOR_USERNAME]: b64ToStringEncoder(formValues?.[formFieldNames.OPERATOR_USERNAME])
                }, Object.values(applicationGenericFieldsList), null, selectedApplicant, null);
            }

            if (enrollPersonFromScratch) {
                requestObject = extractFormValuesToApplicationData({
                        ...formValues,
                        [formFieldNames.ORIGIN_ID]: isOperationModeProtected() ? "SC" : "WP",
                        [formFieldNames.APPLICATION_STATUS]: state,
                        [formFieldNames.DRIVERS_LICENSE_DATA]: driversLicenseData,
                        [formFieldNames.APPLICANT_TYPE]: NATURAL_PERSON,
                        [formFieldNames.OPERATOR_USERNAME]: b64ToStringEncoder(formValues?.[formFieldNames.OPERATOR_USERNAME])
                    },
                    Object.values(applicationGenericFieldsList), null, Object.values(applicantGenericFieldsList))
            }

            summaryReportPayload = requestObject;
            handleCommonServices.handleGetCallSaveApplicationInternal(requestObject, onSuccessSaveApplication(state))
        } else {
            let requestObject = extractFormValuesToApplicationData({
                    ...formValues,
                    [formFieldNames.ORIGIN_ID]: isOperationModeProtected() ? "SC" : "WP",
                    [formFieldNames.APPLICATION_STATUS]: state,
                    [formFieldNames.DRIVERS_LICENSE_DATA]: driversLicenseData,
                    [formFieldNames.APPLICANT_TYPE]: NATURAL_PERSON,
                    [formFieldNames.OPERATOR_USERNAME]: b64ToStringEncoder(formValues?.[formFieldNames.OPERATOR_USERNAME])
                },
                Object.values(applicationGenericFieldsList), null, Object.values(applicantGenericFieldsList))
            handleCommonServices.handleGetCallSaveApplication(requestObject, onSuccessSaveApplication(state))
        }
    };

    const onSuccessSaveApplication = (state) => {
        if (isFormComplete() && state == "CREATED") {
            downloadPreEnrollmentSummaryReport()
        }

        if(state == "CREATED_INCOMPLETE"){
            setDraftApplication(true)
        }

        setShowDraftDialog(false)
        setShowApplicationSubmittedModal(true)
    }

    const createIdentity = () => {
        let identityToSave = selectedPersonForDL ? prepareIdentityToUpdate(selectedPersonForDL) : {};

        if (enrollPersonFromScratch) {
            identityToSave = extractFormValuesToPersonData({
                    ...formValues,
                    [formFieldNames.STATE]: "ACTIVE"
                },
                Object.values([...identityGenericFieldsList, formFieldNames.SPOUSE_NAME]))

            identityToSave.identificationNumbers = [
                ...identityToSave.identificationNumbers,
                {personalNumber: formValuesApplication?.[formFieldNames.ACCOUNT_ID], personalNumberType: "ACCOUNT_ID"}
            ];
        }

        return new Promise((resolve, reject) => {
            handleCommonServices.handleCallSaveIdentity(identityToSave, response => resolve(response), error => reject(error))
        })
    }

    const prepareIdentityToUpdate = (personData) => {
        let identity = {...personData?.applicantData?.demographicDataDTO?.personDataDTO};
        if (!identity) return;

        identity.state = "ACTIVE"
        identity.contactAddress = {
            ...identity.contactAddress,
            email: formValuesApplication?.[applicationStore.REGISTERED_ACCOUNT_EMAIL]
        }
        identity.identificationNumbers = [
            ...identity.identificationNumbers,
            {personalNumber: formValuesApplication?.[formFieldNames.ACCOUNT_ID], personalNumberType: "ACCOUNT_ID"}
        ];

        return identity;
    }

    async function createApplication() {
        if (isFormComplete()) {
            try {
                if (isOperationModeProtected() && !applyWoAccount && !isExistingAccountSelected)
                    await createIdentity();
            } catch (e) {
                dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
                    messageId: I18n.t("err." + e)
                }))
                return;
            }
            saveApplication("CREATED")
        } else {
            openDraftModal()
        }
    }

    function createDraftApplication() {
        saveApplication("CREATED_INCOMPLETE")
    }

    const renderDraftFooter = () => {
        return (
            <div>
                <Button label={I18n.t("label.draft")} icon="pi pi-edit" onClick={() => createDraftApplication()}
                        className="p-button-text"/>
                <Button label={I18n.t("label.continue")} icon="pi pi-arrow" onClick={() => {
                    dispatch(addToAppStore('validationMode', "Hard"))
                    setShowDraftDialog(false)
                }} className="p-button-text"/>
            </div>
        );
    };

    const renderApplicationModalFooter = () => {
        let homeRedirect = isOperationModeProtected() ? "/admin" : "/"
        return (
            <div>
                <Button
                    label={I18n.t("action.home")}
                    icon="pi pi-home"
                    onClick={() => {
                        window.location = ROOT + homeRedirect
                    }}
                    className="p-button"
                />
                {!draftApplication && <Button
                    label={I18n.t("action.printSummary")}
                    icon="pi pi-home"
                    className="p-button"
                    onClick={downloadPreEnrollmentSummaryReport}
                />}
                {configurationData.enableAppointmentsWorkflow && !configurationData.dlGoLive && !draftApplication && isOperationModeNotProtected() && <Button
                    label={I18n.t("action.bookAppointment")}
                    icon="pi pi-calendar-plus"
                    onClick={startBookApp}
                    className="p-button"
                />}
            </div>
        );
    };

    const startBookApp = () => {
        sessionStorage.setItem("selsectedApplicationId", formValues[formFieldNames.APPLICATION_ID]);
        window.location = REDIRECT_TO_APPOINTMENT
    };

    const onPreEnrollmentReportSuccess = (response) => {
        if (isOperationModeProtected()) {
            printSummary(response)
        } else if (response && response.data) {
            loadFile(response.data, "Summary_Report.pdf");
        }
    };

    const printSummary = (response) => {
        if (response?.data) {
            setApplicationReceipt(response.data)
        }
    };

    const downloadPreEnrollmentSummaryReport = () => {
        handleCommonServices.handleCallGeneratePortalReport({
                ...formValues,
                [formFieldNames.OPERATOR_USERNAME]: b64ToStringEncoder(formValues?.[formFieldNames.OPERATOR_USERNAME]),
                [formFieldNames.ORIGIN_ID]: isOperationModeProtected() ? "SC" : "WP",
                [formFieldNames.DRIVERS_LICENSE_DATA]: driversLicenseData
            },
            onPreEnrollmentReportSuccess
        );
    };

    function openDraftModal() {
        if (isPersonalInformationFormValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PERSONAL_DATA, crateErrorObject(personalInformationFormErrors)))
        }
        if (isAddressDataValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.ADDRESS_DATA, crateErrorObject(addressDataErrors)))
        }
        if (isDriverLicenseFormValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.DL_TASK, crateErrorObject(driverLicenseFormErrors)))
        }
        if (isOperationModeNotProtected()){
            setShowDraftDialog(true)
        }
    }

    return (
        <div className="card summary">
            <Dialog
                header={I18n.t(getPath(i18nl.SUCCESS, TRG.LABEL))}
                visible={showApplicationSubmittedModal}
                style={{width: '54vw'}}
                closable={false}
                footer={renderApplicationModalFooter}>
                <Message severity="info" text={I18n.t("message.applicationSuccessfullySubmitted")}/>
            </Dialog>
            <div className="grid">
                {formValues && formValues.capturedFaceData &&
                    <div className="col-12 md:col-3 xl:col-3">
                        <h5><Translate value="label.faceImage"/></h5>
                        <hr className="sectionSeparator"/>
                        <div className="text-center">
                            <img alt={"uploadedFace"} role="presentation"
                                 src={"data:image/jpg;base64," + formValues.capturedFaceData.image} width={100}/>
                        </div>
                    </div>
                }
                <div className={formValues && formValues.capturedFaceData ? "col-12 md:col-9 xl:col-9 p-2" : 'col-12'}>
                    <h5><Translate value="label.applicationData"/></h5>
                    <hr className="sectionSeparator"/>
                    {dynamicColumns}
                </div>
            </div>
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.LABEL))} visible={showDraftDialog} style={{width: '54vw'}}
                    footer={renderDraftFooter} onHide={() => setShowDraftDialog(false)}>
                <Message severity="info" text={I18n.t(getPath(i18nl.APPLICATION_NOT_COMPLETE, TRG.MESSAGE))}/>
            </Dialog>
            <NavButtonsLocal lastStep finalSubmitFunction={() => createApplication()}
                             customOnCancel={() => onCancel(dispatch)}
                             draftFunction={() => openDraftModal()}
                             hideSubmit={!isFormComplete()}
                             hideDraft={(isOperationModeProtected() || isNotRegisteredUserRole(userRights))}/>
        </div>

    )
};
export default SummaryTaskWrapper