import { formFieldNames } from "@mb-react/svarog-ui";
import debounce from 'lodash.debounce';
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { dictionaryReference, projectDictionaries } from "../../../../../../constants/dictionaries";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { getVehicleTypes, handleBrandDictionaries, isMandatory, isReadOnly, isTrailerType, isVehicleType } from "../../../../../../util/businesslogicUtil";
import { useForm } from "react-final-form";
import { useHandleCommonServices } from "../../../../../../services/commonServiceHandler";
import {getDictionaryByKey, getFormValues} from "@mb-react/mb-react-core";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {
  cylinderTypes,
  motorcycleCylinderTypes,
  sedanCylinderTypes,
  stationCylinderTypes,
  vehicleTypes
} from "../../../../../../constants/application";

export const useGetVehicleDetailsFieldsSettings = (formName) => {

  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  let vehicleDataFormValues = useSelector((state) => getFormValues(state, formName.formName))
  const vehicleIdentificationFormValues = useSelector((state) => getFormValues(state, forms.VEHICLE_IDENTIFICATION))
  const form = useForm();
  const fuelOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_FUEL_TYPES))

  vehicleDataFormValues = {...vehicleDataFormValues, ...vehicleIdentificationFormValues}

  const handleCommonServices = useHandleCommonServices()

  const onBrandChange = useCallback(debounce(event => {
    form.change(formFieldNames.VEHICLE_MODEL, "");
    handleBrandDictionaries(vehicleDataFormValues, event?.target?.value, handleCommonServices);
  }, 400), []
  );

  const onCategoryChange = useCallback(debounce(event => {
    form.change(formFieldNames.VEHICLE_SUBCATEGORY, "")
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_SUBCATEGORIES, event.target.value, dictionaryReference.VEHICLE_SUBCATEGORIES);
  }, 400), []
  );

  const onChassisNumberChange = (e) => {
    form.change(formFieldNames.CABIN_NUMBER, e.target.value)
  };

  const getCylinderOptions = () => {
    const electricFuelTypeKey = fuelOptions?.find(fuelType => fuelType?.values?.kr === "Electric")?.key;

    if(vehicleDataFormValues[formFieldNames.FUEL] == electricFuelTypeKey){
      return [
        {
          label: "0",
          value: "0"
        }
      ]
    }

    switch (vehicleIdentificationFormValues?.[formFieldNames.VEHICLE_CATEGORY]) {
      case "01":
        return sedanCylinderTypes;
      case "02":
        return stationCylinderTypes;
      case "13":
        return motorcycleCylinderTypes;
      default:
        return cylinderTypes;
    }
  };

  const handleEngineNumberSettings = useMemo(() => {
    let props = {
      [formFieldNames.ENGINE_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.ENGINE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 13
        },
        exclude: true,
        required: isMandatory(vehicleDataFormValues, formFieldNames.ENGINE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleIdSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_ID]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_ID, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        exclude: true,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_ID, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleTypeSettings = useMemo(() => {
    let props = {
      [genericFormFieldNames.VEHICLE_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, genericFormFieldNames.VEHICLE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          selectOptions: getVehicleTypes()
        },
        required: isMandatory(vehicleDataFormValues, genericFormFieldNames.VEHICLE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleBrandSettings = useMemo(() => {

    let reference = projectDictionaries.VEHICLE_BRANDS;

    if (vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER) {
      reference = projectDictionaries.TRAILER_BRANDS
    }

    let props = {
      [formFieldNames.VEHICLE_BRAND]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_BRAND, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: reference,
          showClear: true,
          // [formFieldNames.ON_CHANGE]: onBrandChange
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_BRAND, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleVehicleCategorySettings = useMemo(() => {

    let reference = projectDictionaries.VEHICLE_CATEGORIES;

    if (vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER) {
      reference = projectDictionaries.TRAILER_CATEGORIES
    }

    let props = {
      [formFieldNames.VEHICLE_CATEGORY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: reference,
          showClear: true,
          [formFieldNames.ON_CHANGE]: onCategoryChange
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleProductionYearSettings = useMemo(() => {
    let props = {
      [formFieldNames.PRODUCTION_YEAR]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.PRODUCTION_YEAR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 4
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.PRODUCTION_YEAR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleProductionCountrySettings = useMemo(() => {
    let props = {
      [formFieldNames.PRODUCTION_COUNTRY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.PRODUCTION_COUNTRY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.COUNTRY_CODES,
          showClear: true,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.PRODUCTION_COUNTRY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])



  const handleChassisNumberSettings = useMemo(() => {

    let props = {
      [formFieldNames.CHASSIS_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CHASSIS_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          keyfilter: /^[^a-zOIQ+\\\-*/.,!?_@#$%^&<>()'"={}\[\]`;:|]+$/,
          maxLength: 17,
          [formFieldNames.ON_CHANGE]: onChassisNumberChange,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.CHASSIS_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleUsageTypeSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_USAGE_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_USAGE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_USAGE_TYPES,
          showClear: true,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_USAGE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleColorSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_COLOR]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_COLOR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_COLORS,
          showClear: true,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_COLOR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVerticalLoadSettings = useMemo(() => {
    let props = {
      [formFieldNames.VERTICAL_LOAD]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VERTICAL_LOAD, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 5,
          digitsOnly: true
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VERTICAL_LOAD, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleSeatsSettings = useMemo(() => {
    let props = {
      [formFieldNames.SEATS]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.SEATS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 2
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: false, //isVehicleType(vehicleIdentificationFormValues) && isMandatory(vehicleDataFormValues, formFieldNames.SEATS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleWheelSizeSettings = useMemo(() => {
    let props = {
      [formFieldNames.WHEEL_SIZE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.WHEEL_SIZE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_WHEEL_TYPES,
          showClear: true,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.WHEEL_SIZE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleAxisSettings = useMemo(() => {
    let props = {
      [formFieldNames.AXIS]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.AXIS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 2
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.AXIS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleTotalWeightSettings = useMemo(() => {
    let props = {
      [formFieldNames.TOTAL_WEIGHT]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.TOTAL_WEIGHT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 5
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.TOTAL_WEIGHT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleVehicleLengthSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_LENGTH]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_LENGTH, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 5
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerLength" : `label.${formFieldNames.VEHICLE_LENGTH}`,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_LENGTH, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleHeightSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_HEIGHT]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_HEIGHT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 5
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerHeight" : `label.${formFieldNames.VEHICLE_HEIGHT}`,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_HEIGHT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleWidthSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_WIDTH]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_WIDTH, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 5
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerWidth" : `label.${formFieldNames.VEHICLE_WIDTH}`,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_WIDTH, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleRestrictionsSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_RESTRICTIONS]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: false,//isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_RESTRICTIONS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW])
        },
        exclude: true,
        required: false, //isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_RESTRICTIONS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleModelSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_MODEL]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_MODEL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          reference: dictionaryReference.VEHICLE_MODELS //isTrailerType(vehicleDataFormValues) && dictionaryReference.TRAILER_MODELS ? dictionaryReference.TRAILER_MODELS : dictionaryReference.VEHICLE_MODELS ? dictionaryReference.VEHICLE_MODELS : []
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_MODEL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleSubcategorySettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_SUBCATEGORY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_SUBCATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          reference: projectDictionaries.VEHICLE_SUBCATEGORIES
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_SUBCATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleMaxSpeedSettings = useMemo(() => {
    let props = {
      [formFieldNames.MAXIMUM_SPEED]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.MAXIMUM_SPEED, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 4
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.MAXIMUM_SPEED, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleLoadWeightSettings = useMemo(() => {
    let props = {
      [formFieldNames.LOAD_WEIGHT]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.LOAD_WEIGHT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.LOAD_WEIGHT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleMaxLoadTrailerWithBrakesSettings = useMemo(() => {
    let props = {
      [formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 5
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleMaxLoadTrailerWithoutBrakesSettings = useMemo(() => {
    let props = {
      [formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 5
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleApprovalTypeSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_APPROVAL_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: true,//isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_APPROVAL_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW])
        },
        required: false,//isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_APPROVAL_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleFrontTintPercentageSettings = useMemo(() => {
    let props = {
      [formFieldNames.FRONT_TINT_PERCENTAGE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.FRONT_TINT_PERCENTAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.FRONT_TINT_PERCENTAGE
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isMandatory(vehicleDataFormValues, formFieldNames.FRONT_TINT_PERCENTAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleRearTintPercentageSettings = useMemo(() => {
    let props = {
      [formFieldNames.REAR_TINT_PERCENTAGE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.REAR_TINT_PERCENTAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.REAR_TINT_PERCENTAGE
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isMandatory(vehicleDataFormValues, formFieldNames.REAR_TINT_PERCENTAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  //specific data
  const handleFuelSettings = useMemo(() => {
    let props = {
      [formFieldNames.FUEL]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.FUEL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          reference: projectDictionaries.VEHICLE_FUEL_TYPES
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isVehicleType(vehicleIdentificationFormValues) && isMandatory(vehicleDataFormValues, formFieldNames.FUEL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleCylindersSettings = useMemo(() => {
    let props = {
      [formFieldNames.CYLINDERS]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CYLINDERS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          selectOptions: getCylinderOptions()
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        // required: false,
        required: isVehicleType(vehicleIdentificationFormValues) && isMandatory(vehicleDataFormValues, formFieldNames.CYLINDERS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues, vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE], vehicleIdentificationFormValues[formFieldNames.VEHICLE_CATEGORY]])

  const handleEngineCapacitySettings = useMemo(() => {
    let props = {
      [formFieldNames.ENGINE_CAPACITY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.ENGINE_CAPACITY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 4
        },
        exclude: vehicleIdentificationFormValues[formFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isMandatory(vehicleDataFormValues, formFieldNames.ENGINE_CAPACITY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleEnginePowerSettings = useMemo(() => {
    let props = {
      [formFieldNames.ENGINE_POWER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.ENGINE_POWER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 4
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isMandatory(vehicleDataFormValues, formFieldNames.ENGINE_POWER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleCabinSizeSettings = useMemo(() => {
    let props = {
      [formFieldNames.CABIN_SIZE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CABIN_SIZE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_CABIN_SIZES,
          showClear: true
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.CABIN_SIZE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleCabinTypeSettings = useMemo(() => {
    let props = {
      [formFieldNames.CABIN_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CABIN_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_CABIN_TYPES,
          showClear: true
        },
        exclude: vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isMandatory(vehicleDataFormValues, formFieldNames.CABIN_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleCabinNumberSettings = useMemo(() => {
    let props = {
      [formFieldNames.CABIN_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CABIN_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          keyfilter: /^[^a-zOIQ+\\\-*/.,!?_@#$%^&<>()'"={}\[\]`;:|]+$/,
          maxLength: 17
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.CABIN_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleIdentificationFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  return {
    ...handleEngineNumberSettings, ...handleVehicleIdSettings, ...handleVehicleTypeSettings, ...handleVehicleBrandSettings, ...handleVehicleCategorySettings, ...handleProductionYearSettings,
    ...handleProductionCountrySettings, ...handleChassisNumberSettings, ...handleVehicleUsageTypeSettings, ...handleVehicleColorSettings,
    ...handleVerticalLoadSettings, ...handleSeatsSettings, ...handleWheelSizeSettings, ...handleAxisSettings, ...handleTotalWeightSettings,
    ...handleVehicleLengthSettings, ...handleVehicleHeightSettings, ...handleVehicleWidthSettings, ...handleVehicleRestrictionsSettings, ...handleVehicleModelSettings,
    ...handleVehicleModelSettings, ...handleVehicleSubcategorySettings, ...handleMaxSpeedSettings, ...handleLoadWeightSettings, ...handleMaxLoadTrailerWithBrakesSettings,
    ...handleMaxLoadTrailerWithoutBrakesSettings, ...handleVehicleApprovalTypeSettings, ...handleRearTintPercentageSettings, ...handleFrontTintPercentageSettings,
    ...handleFuelSettings, ...handleCylindersSettings, ...handleEngineCapacitySettings, ...handleEnginePowerSettings, ...handleCabinSizeSettings,
    ...handleCabinTypeSettings, ...handleCabinNumberSettings
  }

};