import {
    genericFormFieldNames,
    applicationGenericFieldsList,
    vehicleGenericFieldsList, plateGenericFieldsList, proxyGenericFieldsList
} from "@mb-react/krg-getid-form-data-common";
import {
    addToAppStore,
    getFormValues,
    getDictionaryByKey,
    hasValidationErrors,
    addMultipleErrorToStep,
    getFromAppStore,
    previousStep,
    addInitialValueToForm, showSpinner, hideSpinner
} from "@mb-react/mb-react-core";
import { Button, Dialog, Message } from "@mb-react/mb-react-core";
import {
    EUseCases, extractApplicantDataToFormValues,
    extractFormValuesToApplicationDataWithMultiData,
    extractFormValuesToSingleVehicleData,
    formFieldNames
} from "@mb-react/svarog-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { REDIRECT_TO_APPOINTMENT, ROOT } from "../../../../../constants/environment";
import { getPath, i18nl, TRG } from "../../../../../constants/i18n";
import { loadFile, prepareServerStringDate } from "../../../../../sagas/generators/sagaUtil";
import { useHandleCommonServices } from "../../../../../services/commonServiceHandler";
import { BUYER, dictionaryReference, projectDictionaries, SELLER } from "../../../../../constants/general";
import { getLocalSelectOptionTranslation } from "../../../../componentUtil";
import { forms, navigationName } from "../../../../../constants/form";
import { APPLICATION_STEPS, applicationStore } from "../../../../../constants/store";
import { reverseString } from "../../../../../util/util"
import {
    isUseCaseChangeOwnership,
    isOperationModeProtected,
    getAccountLegalEntityType,
    getAccountType,
    isLoggedAccountLegalEntity,
    prepareLETypeForDropDownList,
    isUseCaseReservePlateNumber,
    isUseCaseExchangeRegistration,
    isTrailerType,
    isOperationModeNotProtected,
    isModificationVRUseCase, isNotRegisteredUserRole
} from "../../../../../util/businesslogicUtil";
import { onCancel } from "../../../../../util/componentUtil";
import ApplicationDataForm from "./ApplicationData/ApplicationDataForm";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";
import {
    b64ToStringEncoder,
    prepareProxyForLinkedPerson,
    removeLeadingZero
} from "../../../../../util/dataPreparationUtil";

const SummaryTaskWrapperVR = (props) => {
    let formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let formValuesPersonalInformation = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + "Source"))
    let formValuesAddressData = useSelector((state) => getFormValues(state, forms.ADDRESS_DATA + "Source"))
    let formValuesVehicleIdentification = useSelector((state) => getFormValues(state, forms.VEHICLE_IDENTIFICATION))
    let formValuesVehicleData = useSelector((state) => getFormValues(state, forms.VEHICLE_DATA))
    let formValuesPlateData = useSelector((state) => getFormValues(state, forms.PLATE_DATA))
    let formValuesDocumentsData = useSelector((state) => getFormValues(state, forms.DOCUMENTS_DATA))
    let formValuesProxyData = useSelector((state) => getFormValues(state, forms.PROXY_DATA))
    let additionalVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDITIONAL_VEHICLES))
    let selectedAdditionalVehicles = useSelector(state => getFromAppStore(state, applicationStore.SELECTED_ADDITIONAL_VEHICLE))
    let vehicleFromSdms = useSelector(state => getFromAppStore(state, applicationStore.ADDED_SDMS_VEHICLES))
    let isVehicleFromSDMSAdded = useSelector(state => getFromAppStore(state, applicationStore.ADDED_VEHICLES_FROM_SDMS))
    let updateVehicle = useSelector(state => getFromAppStore(state, applicationStore.UPDATE_VEHICLE))
    const formValuesApplicationData = useSelector((state) => getFormValues(state, forms.APPLICATION_DATA))

    const isPersonalInformationFormValid = useSelector((state) => hasValidationErrors(state, forms.PERSONAL_INFORMATION + "Source"));
    const isAddressDataValid = useSelector((state) => hasValidationErrors(state, forms.ADDRESS_DATA + "Source"));
    const isVehicleIdentificationFormValid = useSelector((state) => hasValidationErrors(state, forms.VEHICLE_IDENTIFICATION));
    const isVehicleDataFormValid = useSelector((state) => hasValidationErrors(state, forms.VEHICLE_DATA));
    const isPlateDataFormValid = useSelector((state) => hasValidationErrors(state, forms.PLATE_DATA));
    const isApplicationDataFormValid = useSelector((state) => hasValidationErrors(state, forms.APPLICATION_DATA));

    const sourceVehicleStepCompleted = useSelector((state) => getFromAppStore(state, 'sourceVehicleStepCompleted'));
    const sourcePersonStepCompleted = useSelector((state) => getFromAppStore(state, 'sourcePersonStepCompleted'));
    const targetPersonStepCompleted = useSelector((state) => getFromAppStore(state, 'targetPersonStepCompleted'));
    const proxyDataStepCompleted = useSelector((state) => getFromAppStore(state, 'proxyDataStepCompleted'))
    const isPlateReserved = useSelector((state) => getFromAppStore(state, 'plateReserved'))

    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'))
    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
    const totalPercentageSellers = useSelector((state) => getFromAppStore(state, 'totalPercentageSellers'))
    const totalPercentageBuyers = useSelector((state) => getFromAppStore(state, 'totalPercentageBuyers'))
    const reservePlateNumberEntity = useSelector((state) => getFromAppStore(state, 'reservePlateNumberEntity'))
    const vehicleFromSystem = useSelector((state) => getFromAppStore(state, applicationStore.VEHICLE_FROM_SYSTEM));
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS));

    //get selected vehicle from table
    const selectedVehicle = useSelector((state) => getFromAppStore(state, applicationStore.SELECTED_VEHICLE));

    const [internalVid, setInternalVid] = useState(null);

    let formValuesPerson = { ...formValuesApplication, ...formValuesPersonalInformation, ...formValuesAddressData, ...formValuesProxyData }

    let formValuesVehicle = { ...formValuesApplication, ...formValuesVehicleIdentification, ...formValuesVehicleData, ...formValuesPlateData }

    let listOfSellersValues = listOfSellers?.[0]?.applicantData ? extractApplicantDataToFormValues(listOfSellers?.[0]?.applicantData) : {}

    let formValues = {
        formValuesApplication, ...formValuesPersonalInformation, ...formValuesAddressData, ...formValuesVehicleIdentification,
        ...formValuesVehicleData, ...formValuesPlateData, ...formValuesDocumentsData, ...listOfSellersValues, ...selectedVehicle, ...formValuesProxyData
    }

    const [showDraftDialog, setShowDraftDialog] = useState(false);
    const [showApplicationSubmittedModal, setShowApplicationSubmittedModal] = useState(false);
    const [showChassisNumberConfirmationDialog, setShowChassisNumberConfirmationDialog] = useState(false);
    const [chassisNumberConfirmed, setChassisNumberConfirmed] = useState(false);

    const provinceOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_OF_REGISTRATION))
    const villageOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_OF_REGISTRATION))
    const countryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.COUNTRY_CODES))
    const provincesOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_PLACE_OF_BIRTH))
    const villageOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
    const provincesAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_ADDRESS))
    const infoCenterAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.INFO))
    const villageAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_ADDRESS))
    const buildingClassAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BUILDING_CLASS))
    const genderOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.GENDER_CODES))
    const maritalOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.MARITAL_CODES))
    const foodCenterOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD_CENTER_INDICATOR))
    const cateringCenterProvinceOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cateringCenterNumberOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD))
    const professionOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROFESSION))
    const nationalityOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.NATIONALITIES))
    const bloodGroupOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BLOOD_GROUP))
    const categoryOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_CATEGORIES))
    const brandOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_BRANDS))
    const cabinTypeOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_CABIN_TYPES))
    const cabinSizeOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.VEHICLE_CABIN_SIZES))
    const vehicleColorOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_COLORS))
    const vehicleModelOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_MODELS))
    const usageTypeOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_USAGE_TYPES))
    const fuelOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_FUEL_TYPES))
    const wheelSizeOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_WHEEL_TYPES))
    const vehicleProvincesOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.PLATE_PROVINCE))
    const trailerCategoryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_CATEGORIES))
    const trailerBrandOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_BRANDS))
    const trailerVehicleModelOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_MODELS))
    const trailerSubCategoryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_SUBCATEGORIES))
    const vehicleSubCategoryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.VEHICLE_SUBCATEGORIES))
    const initialModifyValues = useSelector((state) => getFromAppStore(state, applicationStore.INITIAL_MODIFY_VALUES));
    const modifiedVehicleValues = useSelector((state) => getFromAppStore(state, applicationStore.MODIFIED_FIELDS));

    const dispatch = useDispatch();
    const handleCommonServices = useHandleCommonServices();

    const [applicationReceipt, setApplicationReceipt] = useState();
    const [draftApplication, setDraftApplication] = useState(false)
    const dynamicColumns = Object.keys(formValues).map((x, i) => {

        if (["applicationType", "workflow", "ownerRole", "enrollmentStatus"].includes(x)) {
            return;
        }

        return formValues[x] && typeof formValues[x] != "object" && <div className="grid IDsummary" key={i}>
            <div className="col-12 xl:col-6">
                {labelValue(x)}
            </div>
            <div className="col-12 xl:col-6 summaryValue">
                {decorateField(x, formValues[x])}
            </div>
        </div>
    });

    function labelValue(formLabel) {
        if (isTrailerType(formValues)) {
            switch (formLabel) {
                case formFieldNames.VEHICLE_CATEGORY:
                    return I18n.t("label.trailerCategory")
                case formFieldNames.VEHICLE_SUBCATEGORY:
                    return I18n.t("label.trailerSubcategory")
                case formFieldNames.VEHICLE_BRAND:
                    return I18n.t("label.trailerBrand")
                case formFieldNames.VEHICLE_MODEL:
                    return I18n.t("label.trailerModel")
                case formFieldNames.VEHICLE_LENGTH:
                    return I18n.t("label.trailerLength")
                case formFieldNames.VEHICLE_HEIGHT:
                    return I18n.t("label.trailerHeight")
                case formFieldNames.VEHICLE_WIDTH:
                    return I18n.t("label.trailerWidth")
                default:
                    return I18n.t("label." + formLabel)
            }
        }
        return I18n.t("label." + formLabel)
    }

    const isVehicleExistsOnSuccess = (response) => {
        if (response) {
            setShowChassisNumberConfirmationDialog(true);
            setInternalVid(response)
        }
    }

    const isVehicleExistsOnError = () => {
        console.log("ERROR...")
    }

    const checkDictionaryValues = () => {
        if (!cityOptions && formValues[formFieldNames.PLACE_OF_BIRTH_PROVINCE])
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[formFieldNames.PLACE_OF_BIRTH_PROVINCE], dictionaryReference.CITIES_PLACE_OF_BIRTH)
        if (!villageOptions && formValues[formFieldNames.PLACE_OF_BIRTH_CITY])
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[formFieldNames.PLACE_OF_BIRTH_CITY], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
        if (!cityOfRegistrationOptions && formValues[genericFormFieldNames.PROVINCE_OF_REGISTRATION])
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[genericFormFieldNames.PROVINCE_OF_REGISTRATION], dictionaryReference.CITIES_OF_REGISTRATION)
        if (!villageOfRegistrationOptions && formValues[genericFormFieldNames.CITY_OF_REGISTRATION])
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, formValues[genericFormFieldNames.CITY_OF_REGISTRATION], dictionaryReference.VILLAGES_OF_REGISTRATION)
        if (!vehicleModelOptions && formValues[formFieldNames.VEHICLE_BRAND])
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_MODELS, formValues[formFieldNames.VEHICLE_BRAND], dictionaryReference.VEHICLE_MODELS)
        if (!cityAddressOptions && formValues[formFieldNames.ADDRESS_PROVINCE]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, formValues[formFieldNames.ADDRESS_PROVINCE], projectDictionaries.INFO)
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[formFieldNames.ADDRESS_PROVINCE], dictionaryReference.CITIES_ADDRESS)
        }
        if (!villageAddressOptions && formValues[formFieldNames.ADDRESS_CITY])
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, formValues[formFieldNames.ADDRESS_CITY], dictionaryReference.VILLAGES_ADDRESS)
    }

    const checkModifiedVehicleData = () => {
        if (!isModificationVRUseCase(formValuesApplication)) return;

        const fieldsMap = {
            [formFieldNames.VEHICLE_SUBCATEGORY]: genericFormFieldNames.VEHICLE_SUB_CATEGORY_CHANGED,
            [formFieldNames.PLATE_PROVINCE]: genericFormFieldNames.PLATE_PROVINCE_CHANGED,
            [formFieldNames.PLATE_USAGE]: genericFormFieldNames.PLATE_USAGE_CHANGED,
            [formFieldNames.PLATE_NUMBER_LATIN]: genericFormFieldNames.LICENSE_NUMBER_CHANGED,
            [formFieldNames.VEHICLE_COLOR]: genericFormFieldNames.COLOR_CHANGED
        };

        let modifiedVal = {}
        Object.keys(fieldsMap).forEach(key => {
            if (initialModifyValues[key] && formValues[key] && formValues[key] !== initialModifyValues[key]) {
                modifiedVal = { ...modifiedVal, [fieldsMap[key]]: true }
            }
        })

        dispatch(addToAppStore(applicationStore.MODIFIED_FIELDS, modifiedVal))
    }


    useEffect(() => {
        syncApplicantPercentage();
        checkDictionaryValues();
        if (formValuesApplication.transactionId && formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.MODIFY_VEHICLE_DATA) {
            setInternalVid(formValuesApplication.transactionId)
        } else if (!internalVid) {
            if (formValuesVehicleIdentification.transactionId && formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.MODIFY_VEHICLE_DATA) {
                setInternalVid(formValuesVehicleIdentification.transactionId)
            } else {
                if (formValuesApplication.applicationID) {
                    setInternalVid(reverseString(formValuesApplication.applicationID));
                }
            }
        }

        if (!vehicleFromSystem && isFormComplete() && formValuesVehicleIdentification &&
            formValuesVehicleIdentification[formFieldNames.CHASSIS_NUMBER] && formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.MODIFY_VEHICLE_DATA) {
            let requestData = {
                chassisNumber: formValuesVehicleIdentification[formFieldNames.CHASSIS_NUMBER]
            }
            handleCommonServices.isVehicleExists(requestData, isVehicleExistsOnSuccess, isVehicleExistsOnError)
        }

        if (isModificationVRUseCase(formValuesApplication) && !isNotRegisteredUserRole(userRights)) {
            checkModifiedVehicleData()
        }


    }, [])

    useEffect(() => {
        if (applicationReceipt) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
                "<html><head><title>PRINT SUMMARY</title></head><body><iframe title='PRINT SUMMARY'  width='100%' height='100%' src='data:application/pdf;base64, " + applicationReceipt + "'></iframe></body></html>"
            );
        }
    }, [applicationReceipt]);

    function decorateField(formLabel, formValue) {
        switch (formLabel) {
            case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(provinceOfRegistrationOptions, formValue)
            case genericFormFieldNames.CITY_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(cityOfRegistrationOptions, formValue)
            case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(villageOfRegistrationOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_COUNTRY:
                return getLocalSelectOptionTranslation(countryOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
                return getLocalSelectOptionTranslation(provincesOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_CITY:
                return getLocalSelectOptionTranslation(cityOptions, formValue)
            case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:
                return getLocalSelectOptionTranslation(villageOptions, formValue)
            case formFieldNames.ADDRESS_PROVINCE:
                return getLocalSelectOptionTranslation(provincesAddressOptions, formValue)
            case formFieldNames.ADDRESS_CITY:
                return getLocalSelectOptionTranslation(cityAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_VILLAGE:
                return getLocalSelectOptionTranslation(villageAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_INFORMATION_CENTER:
                return getLocalSelectOptionTranslation(infoCenterAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_BUILDING_CLASS:
                return getLocalSelectOptionTranslation(buildingClassAddressOptions, formValue)
            case formFieldNames.GENDER:
                return getLocalSelectOptionTranslation(genderOptions, formValue)
            case formFieldNames.MARITAL_STATUS:
                return getLocalSelectOptionTranslation(maritalOptions, formValue)
            case genericFormFieldNames.FOOD_CENTER_INDICATOR:
                const selectedOption = getLocalSelectOptionTranslation(foodCenterOptions, formValue);
                return selectedOption === "NO" ? "NO" : "YES";
            case genericFormFieldNames.CATERING_CENTER_PROVINCE:
                return getLocalSelectOptionTranslation(cateringCenterProvinceOptions, formValue)
            case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
                return getLocalSelectOptionTranslation(cateringCenterNumberOptions, formValue)
            case formFieldNames.POSITION:
                return getLocalSelectOptionTranslation(professionOptions, formValue)
            case formFieldNames.NATIONALITY:
                return getLocalSelectOptionTranslation(nationalityOptions, formValue)
            case formFieldNames.BLOOD_GROUP:
                return getLocalSelectOptionTranslation(bloodGroupOptions, formValue)
            case formFieldNames.VEHICLE_CATEGORY:
                if (isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerCategoryOptions, formValue)
                } else {
                    return getLocalSelectOptionTranslation(categoryOptions, formValue)
                }
            case formFieldNames.VEHICLE_SUBCATEGORY:
                if (isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerSubCategoryOptions, formValue)
                } else {
                    return getLocalSelectOptionTranslation(vehicleSubCategoryOptions, formValue)
                }
            case formFieldNames.VEHICLE_BRAND:
                if (isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerBrandOptions, formValue)
                } else {
                    return getLocalSelectOptionTranslation(brandOptions, formValue)
                }
            case formFieldNames.VEHICLE_COLOR:
                return getLocalSelectOptionTranslation(vehicleColorOptions, formValue)
            case formFieldNames.VEHICLE_MODEL:
                if (isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerVehicleModelOptions, formValue)
                } else {
                    return getLocalSelectOptionTranslation(vehicleModelOptions, formValue)
                }
            case formFieldNames.VEHICLE_USAGE_TYPE:
                return getLocalSelectOptionTranslation(usageTypeOptions, formValue)
            case formFieldNames.CABIN_TYPE:
                return getLocalSelectOptionTranslation(cabinTypeOptions, formValue)
            case formFieldNames.CABIN_SIZE:
                return getLocalSelectOptionTranslation(cabinSizeOptions, formValue)
            case formFieldNames.FUEL:
                return getLocalSelectOptionTranslation(fuelOptions, formValue)
            case formFieldNames.WHEEL_SIZE:
                return getLocalSelectOptionTranslation(wheelSizeOptions, formValue)
            case formFieldNames.PLATE_PROVINCE:
                return getLocalSelectOptionTranslation(vehicleProvincesOptions, formValue)
            case "enrolmentStarted":
                return prepareServerStringDate(formValue)
            case "operatorUsername":
                return b64ToStringEncoder(formValue)
            case formFieldNames.DOCUMENT_TYPE:
                return I18n.t("label." + formValue)
            case genericFormFieldNames.TEMPORARY_PLATE_NUMBER:
                const plateValue = formValue.split(" ");
                if (plateValue.length === 2) {
                    const formattedPlateNumber = `${plateValue[0]} ${parseInt(plateValue[1], 10)}`;
                    return formattedPlateNumber;
                }
                return formValue;
            default:
                if (typeof formValue == "boolean") return formValue.toString();
                return typeof formValue == "string" || typeof formValue == "number" ? formValue : prepareServerStringDate(formValue)
        }
    }


    const isFormComplete = () => {
        if (!isOperationModeProtected() && formValuesApplication?.[formFieldNames.USE_CASE] === EUseCases.RESERVE_PLATE_NUMBER &&
            proxyDataStepCompleted && isPlateReserved) {
            return true;
        }
        if ((formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.REPLACE &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.PARTLY_DAMAGED &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.MODIFY_VEHICLE_DATA &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.EXPIRED &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.EXCHANGE_REGISTRATION &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.NEW &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.CHANGE_OWNERSHIP &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.CANCEL) && !isPlateReserved) {
            return false;
        }
        if (isPersonalInformationFormValid || isAddressDataValid || (isVehicleIdentificationFormValid && !isVehicleFromSDMSAdded) ||
            (isVehicleDataFormValid && !isVehicleFromSDMSAdded) || isPlateDataFormValid ||
            !sourcePersonStepCompleted || (!sourceVehicleStepCompleted && formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.RESERVE_PLATE_NUMBER) ||
            !proxyDataStepCompleted || isApplicationDataFormValid) {
            return false;
        }
        return true;
    };

    const syncApplicantPercentage = () => {
        if (listOfSellers?.length > 0) {
            listOfSellers.forEach((seller) => {
                let percForPerson = totalPercentageSellers.find((obj) => {
                    return obj.id == seller.sellerId;
                });
                seller.applicantData.ownershipDTO = {
                    ownerRole: SELLER,
                    percent: percForPerson?.percentage ?? 0,
                    lead: percForPerson?.lead ?? false
                }
            });
        }
        if (listOfBuyers?.length > 0) {
            listOfBuyers.forEach((buyer) => {
                let percForPerson = totalPercentageBuyers.find((obj) => {
                    return obj.id == buyer.buyerId;
                })
                buyer.applicantData.ownershipDTO = {
                    ownerRole: BUYER,
                    percent: percForPerson.percentage,
                    lead: percForPerson.lead
                }
            });
        }
    }

    const saveApplication = (state) => {
        dispatch(showSpinner(I18n.t('message.saveAppMessage')))
        let applicantList = listOfSellers ? listOfSellers.map((value) => { return value.applicantData }) : [];

        if (!isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApplication)) {
            applicantList = applicantList.concat(reservePlateNumberEntity);
        }

        if (isUseCaseChangeOwnership(formValuesApplication) && listOfBuyers?.length > 0) {
            let listOfApplicantsBuyers = listOfBuyers.map((value) => { return value.applicantData })
            applicantList = applicantList.concat(listOfApplicantsBuyers)
        }

        let additionalVehicleValue = null;
        if (isUseCaseExchangeRegistration(formValuesApplication) && additionalVehicles.length > 0) {
            additionalVehicleValue = additionalVehicles[0].transactionId
        }


        let ScVerificationRequired = false;

        if (isOperationModeNotProtected() && isModificationVRUseCase(formValuesApplication) && (isNotRegisteredUserRole(userRights) || !vehicleFromSystem)) {
            ScVerificationRequired = true;
        }

        let requestObject = extractFormValuesToApplicationDataWithMultiData({
            ...formValuesPerson,
            ...formValuesDocumentsData,
            ...modifiedVehicleValues,
            ...formValuesPlateData,
            ...formValuesApplicationData,
            [formFieldNames.APPLICATION_STATUS]: state,
            [formFieldNames.TRANSACTION_ID]: internalVid,
            "additionalVehicle": additionalVehicleValue,
            "scVerificationRequired": ScVerificationRequired,
            [formFieldNames.ORIGIN_ID]: isOperationModeProtected() ? "SC" : "WP",
            [genericFormFieldNames.TEMPORARY_PLATE_NUMBER]: formValuesVehicleIdentification?.[genericFormFieldNames.TEMPORARY_PLATE_NUMBER],
            [genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES]: formValuesVehicleIdentification?.[genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES],
            [formFieldNames.OPERATOR_USERNAME]: b64ToStringEncoder(formValues?.[formFieldNames.OPERATOR_USERNAME])
        }, [...applicationGenericFieldsList, ...proxyGenericFieldsList, "additionalVehicle", "scVerificationRequired", "sourcePlate"], null, applicantList, null);

        if ((formValuesVehicleIdentification.transactionId || chassisNumberConfirmed) &&
            formValuesApplication?.[formFieldNames.USE_CASE] !== EUseCases.MODIFY_VEHICLE_DATA) {
            handleCommonServices.handleGetCallSaveApplication(requestObject, onCreateVehicleSuccess(state), () => {
                dispatch(hideSpinner())
            })
            return;
        } else {

            let vehicleRequestObject = createVehicleDTO(state)

            dispatch(showSpinner(I18n.t('message.saveAppMessage')))

            //generate sagaDTO

            let applicationRequest = generateTransactionDTOForRequest(formValues.applicationID, "BPC", requestObject, "CREATE")
            let vehicleRequest = generateTransactionDTOForRequest(internalVid, "VS", vehicleRequestObject, updateVehicle ? "UPDATE" : "CREATE")

            let sagaRequestObject = {
                "transactionId": formValues.applicationID,
                "transactions": [applicationRequest, vehicleRequest]
            }

            handleCommonServices.handleCallSaveTransaction(sagaRequestObject, onCreateVehicleSuccess(state), onCreateVehicleError)
        }
    };

    function generateTransactionDTOForRequest(txID, name, requestObject, operation) {
        return {
            "txID": txID,
            "name": name,
            "json": JSON.stringify(requestObject),
            "operation": operation
        }
    }

    function createVehicleDTO(state) {
        dispatch(hideSpinner())

        if (formValuesApplication?.[formFieldNames.USE_CASE] === EUseCases.MODIFY_VEHICLE_DATA) {
            state = "MODIFICATION"
        }

        let plateGenerics = plateGenericFieldsList;
        if (plateGenericFieldsList.indexOf(formFieldNames.APPLICANT_TYPE)) {
            plateGenerics.push(formFieldNames.APPLICANT_TYPE)
        }
        if (plateGenericFieldsList.indexOf(formFieldNames.APPLICANT_SUB_TYPE)) {
            plateGenerics.push(formFieldNames.APPLICANT_SUB_TYPE)
        }

        let applicantSubType = null;
        if (isLoggedAccountLegalEntity()) {
            applicantSubType = prepareLETypeForDropDownList(getAccountLegalEntityType());
            if (applicantSubType === "-") {
                applicantSubType = null;
            }
        }

        if (isOperationModeProtected() && !formValuesVehicle[formFieldNames.CHASSIS_NUMBER] && vehicleFromSdms && vehicleFromSdms?.length > 0) {
            formValuesVehicle = { ...formValuesVehicle, ...vehicleFromSdms[0] }
        }

        let vehicleRequestObject = extractFormValuesToSingleVehicleData({
            ...formValuesVehicle,
            transactionId: internalVid,
            status: state,
            [formFieldNames.APPLICANT_TYPE]: getAccountType(),
            [formFieldNames.APPLICANT_SUB_TYPE]: applicantSubType
        },
            Object.values(vehicleGenericFieldsList), Object.values(plateGenericFieldsList))

        return vehicleRequestObject;
    }

    const onCreateVehicleError = () => {
        dispatch(hideSpinner())
    }
    const onCreateVehicleSuccess = (state) => {
        dispatch(hideSpinner())
        if (isFormComplete() && ["CREATED", "MODIFICATION"].includes(state)) {
            downloadPreEnrollmentSummaryReport()
        }

        if (state == "CREATED_INCOMPLETE") {
            setDraftApplication(true)
        }

        setShowDraftDialog(false)
        setShowApplicationSubmittedModal(true)
    }

    function openDraftModal() {
        if (isUseCaseChangeOwnership(formValuesApplication) && !targetPersonStepCompleted) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.TARGET_OWNER, { "error": "Target owner step invalid" }, navigationName.MAIN_NAVIGATION))
        }
        if (isPersonalInformationFormValid || isAddressDataValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PERSONAL_DATA, { "error": "Person step invalid" }, navigationName.MAIN_NAVIGATION))
        }
        if ((isVehicleDataFormValid && !isVehicleFromSDMSAdded) || (isVehicleIdentificationFormValid && !isVehicleFromSDMSAdded)) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.VEHICLE_DATA, { "error": "Vehicle step invalid" }, navigationName.MAIN_NAVIGATION))
        }
        if (isPlateDataFormValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PLATE_HANDLING, { "error": "Plate step invalid" }, navigationName.MAIN_NAVIGATION))

        }
        setShowDraftDialog(true)
    }

    function createApplication() {
        if (isFormComplete()) {
            if (listOfSellers && listOfSellers.length == 1) {
                if (listOfSellers[0].applicantData?.ownershipDTO?.percent && listOfSellers[0].applicantData?.ownershipDTO?.lead) {
                    listOfSellers[0].applicantData.ownershipDTO.percent = 100;
                    listOfSellers[0].applicantData.ownershipDTO.lead = true;
                } else {
                    let ownershipDTO = {
                        percent: 100,
                        lead: true
                    }
                    listOfSellers[0].applicantData.ownershipDTO = ownershipDTO;
                }
            }

            saveApplication("CREATED")
        } else {
            openDraftModal()
        }
    }

    function createDraftApplication() {
        saveApplication("CREATED_INCOMPLETE")
    }

    const renderDraftFooter = () => {
        return (
            <div>
                <Button label={I18n.t("label.draft")} icon="pi pi-edit" onClick={() => createDraftApplication()}
                    className="p-button-text" />
                <Button label={I18n.t("label.continue")} icon="pi pi-arrow" onClick={() => {
                    dispatch(addToAppStore('validationMode', "Hard"))
                    setShowDraftDialog(false)
                }} className="p-button-text" />
            </div>
        );
    };

    const renderChassisNumberFooter = () => {
        return (
            <div>
                <Button label={I18n.t("action.confirm")} icon="pi pi-edit" onClick={() => {
                    setChassisNumberConfirmed(true)
                    setShowChassisNumberConfirmationDialog(false)
                }}
                    className="p-button-text" />
                <Button label={I18n.t("action.alterChassisNumber")} icon="pi pi-arrow" onClick={() => {
                    dispatch(addMultipleErrorToStep(APPLICATION_STEPS.VEHICLE_DATA, { "error": "Vehicle step invalid" }, navigationName.MAIN_NAVIGATION))
                    dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, true))
                    dispatch(addToAppStore('sourceVehicleStepCompleted', false))
                    dispatch(addInitialValueToForm(forms.VEHICLE_IDENTIFICATION, formFieldNames.CHASSIS_NUMBER, null))
                    setChassisNumberConfirmed(false)
                    setShowChassisNumberConfirmationDialog(false)
                }} className="p-button-text" />
            </div>
        );
    };

    const renderApplicationModalFooter = () => {
        return (
            <div>
                <Button
                    label={I18n.t("action.home")}
                    icon="pi pi-home"
                    onClick={() => {
                        window.location = ROOT + "/"
                    }}
                    className="p-button"
                />
                {!draftApplication && <Button
                    label={I18n.t("action.printSummary")}
                    icon="pi pi-home"
                    className="p-button"
                    onClick={downloadPreEnrollmentSummaryReport}
                />}
                {configurationData.enableAppointmentsWorkflow && !draftApplication && <Button
                    label={I18n.t("action.bookAppointment")}
                    icon="pi pi-calendar-plus"
                    onClick={startBookApp}
                    className="p-button"
                />}
            </div>
        );
    };

    const printSummary = (response) => {
        if (response?.data) {
            setApplicationReceipt(response.data)
        }
    };

    const startBookApp = () => {
        sessionStorage.setItem("selsectedApplicationId", formValues[formFieldNames.APPLICATION_ID]);
        window.location = REDIRECT_TO_APPOINTMENT
    };

    const onPreEnrollmentReportSuccess = (response) => {
        if (isOperationModeProtected()) {
            printSummary(response)
        } else {
            if (response && response.data) {
                loadFile(response.data, "Summary_Report.pdf");
            }
        }
    };

    const prepareApplicantsForSummaryReport = (applicantList) => {
        if (listOfSellers?.length > 1) {
            listOfSellers.forEach(s => applicantList.push(prepareProxyForLinkedPerson(s?.applicantData, formValuesProxyData)))
        } else if (listOfSellers?.length === 1) {
            applicantList.push(prepareProxyForLinkedPerson(listOfSellers[0]?.applicantData, formValuesProxyData));
        }
        if (listOfBuyers?.length > 1) {
            listOfBuyers.forEach(s => applicantList.push(prepareProxyForLinkedPerson(s?.applicantData, formValuesProxyData)))
        } else if (listOfBuyers?.length === 1) {
            applicantList.push(prepareProxyForLinkedPerson(listOfBuyers[0]?.applicantData, formValuesProxyData));
        }
    }

    function preparePlateDataForReport(vehicleFormValues) {
        if (vehicleFormValues && vehicleFormValues[formFieldNames.PLATE_NUMBER_LATIN] && vehicleFormValues[formFieldNames.PLATE_NUMBER_LATIN].substring(0, 1).match(/[A-Z]/)) {
            let plateNumberFormated = vehicleFormValues[formFieldNames.PLATE_NUMBER_LATIN].substring(0, 1) + removeLeadingZero(vehicleFormValues[formFieldNames.PLATE_NUMBER_LATIN].substring(1));
            vehicleFormValues[formFieldNames.PLATE_NUMBER_LATIN] = plateNumberFormated
            vehicleFormValues[formFieldNames.PLATE_NUMBER] = plateNumberFormated
            return vehicleFormValues;
        }

        return vehicleFormValues
    }

    const downloadPreEnrollmentSummaryReport = () => {
        let applicantList = [];

        prepareApplicantsForSummaryReport(applicantList);

        if (isOperationModeProtected() && !formValuesVehicle[formFieldNames.CHASSIS_NUMBER] && vehicleFromSdms && vehicleFromSdms?.length > 0) {
            formValuesVehicle = { ...formValuesVehicle, ...vehicleFromSdms[0] }
        }

        formValuesVehicle = preparePlateDataForReport(formValuesVehicle);

        let vehicleRequestObject = [extractFormValuesToSingleVehicleData({
            ...formValuesVehicle
        },
            Object.values(vehicleGenericFieldsList), Object.values(plateGenericFieldsList))]

        if (isUseCaseExchangeRegistration(formValuesApplication) && selectedAdditionalVehicles) {
            vehicleRequestObject.push(selectedAdditionalVehicles)
        }

        handleCommonServices.handleGenerateVREnrollmentReport({
            ...formValuesPerson,
            ...modifiedVehicleValues,
            ...formValuesProxyData,
            [formFieldNames.ORIGIN_ID]: isOperationModeProtected() ? "SC" : "WP",
            [genericFormFieldNames.TEMPORARY_PLATE_NUMBER]: formValuesVehicleIdentification?.[genericFormFieldNames.TEMPORARY_PLATE_NUMBER],
            [genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES]: formValuesVehicleIdentification?.[genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES]
        },
            applicantList,
            vehicleRequestObject,
            onPreEnrollmentReportSuccess
        );
    };

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const chassisNumber = formValuesVehicleIdentification[formFieldNames.CHASSIS_NUMBER];
    const chassisNumberMatchMessage = I18n.t(getPath(i18nl.CHASSIS_NUMBER_MATCH_MESSAGE, TRG.MESSAGE));

    return (
        <div className="card summary">
            <Dialog header={I18n.t(getPath(i18nl.APPLICATION_COMPLETED, TRG.LABEL))} visible={showApplicationSubmittedModal}
                style={{ width: '54vw' }} footer={renderApplicationModalFooter}>
                <Message severity="info" text={I18n.t("message.applicationSuccessfullySubmitted")} />
            </Dialog>
            <div className="grid">
                {formValues && formValues.capturedFaceData &&
                    <div className="col-12 md:col-3 xl:col-3">
                        <h5><Translate value="label.faceImage" /></h5>
                        <hr className="sectionSeparator" />
                        <div className="text-center">
                            <img alt={"uploadedFace"} role="presentation"
                                src={"data:image/jpg;base64," + formValues.capturedFaceData.image} width={100} />
                        </div>
                    </div>
                }
                <div>
                    TRANSACTION SERVICE ON
                </div>
                <div className={formValues && formValues.capturedFaceData ? "col-12 md:col-9 xl:col-9 p-2" : 'col-12'}>
                    <h5><Translate value="label.applicationData" /></h5>
                    <hr className="sectionSeparator" />
                    <ApplicationDataForm />
                </div>
                <div className={formValues && formValues.capturedFaceData ? "col-12 md:col-9 xl:col-9 p-2" : 'col-12'}>
                    <h5><Translate value="label.summary" /></h5>
                    <hr className="sectionSeparator" />
                    {dynamicColumns}
                </div>
            </div>
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.LABEL))} visible={showDraftDialog} style={{ width: '54vw' }}
                footer={renderDraftFooter} onHide={() => setShowDraftDialog(false)}>
                <Message severity="info" text={I18n.t(getPath(i18nl.APPLICATION_NOT_COMPLETE, TRG.MESSAGE))} />
            </Dialog>
            <Dialog header={I18n.t(getPath(i18nl.CHASSIS_NUMBER_DIALOG, TRG.LABEL))} visible={showChassisNumberConfirmationDialog} style={{ width: '54vw' }}
                footer={renderChassisNumberFooter} onHide={() => setShowChassisNumberConfirmationDialog(false)}>
                <Message severity="info" content={
                    <div>
                        {chassisNumberMatchMessage} <span style={{ color: 'red' }}>{chassisNumber}</span>
                    </div>
                } />
            </Dialog>
            <NavButtonsLocal lastStep customOnPrevious={() => onPrevious()}
                finalSubmitFunction={() => createApplication()}
                customOnCancel={() => onCancel(dispatch)}
                draftFunction={() => openDraftModal()}
                hideSubmit={!isFormComplete()}
                hideDraft={(isOperationModeProtected() || isNotRegisteredUserRole(userRights))} />
        </div>

    )
};
export default SummaryTaskWrapperVR